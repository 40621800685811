
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getReportByEvent } from '@/services/seller.service'
import { SellerReport } from '@/interfaces/ticket.interface'
import { ErrorMessages } from '@/helpers/constants'
import { State } from 'vuex-class'

@Component({
  components: {
    SellerHeader,
  },
})
export default class Report extends Vue {
  //@State(({ sellerState }) => sellerState.eventSelected) eventId!: number;
  eventId!: number = localStorage.getItem('selectedEvent')
  public dataReport: SellerReport[] = []
  public total_net = 0
  public total_comision = 0
  public total = 0

  async getReport() {
    const loading = this.$loading.show()
    try {
      const data = await getReportByEvent(this.eventId)
      this.dataReport = data

      data.forEach(x => {
        this.total_net = this.total_net + x.net_total
        this.total_comision = this.total_comision + x.comision
        this.total = this.total + x.total
      })
      loading.hide()
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON)
      loading.hide()
    }
  }

  mounted() {
    this.getReport()
  }
}
